import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalVariable } from '../global';
import { from } from 'rxjs';
import { ServerService } from '../server.service';
import { ModalController, NavController, LoadingController, AlertController, ToastController} from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.page.html',
  styleUrls: ['./product-detail.page.scss'],
})
export class ProductDetailPage implements OnInit {
  submitAttempt: boolean = false;
  productForm: FormGroup;
  list: any;
  status: any;
  id: any;

  constructor(public route: ActivatedRoute, public global: GlobalVariable, public service: ServerService, public navCtrl: NavController, public alertCtrl: AlertController, public router: Router, public loadingCtrl: LoadingController, public toastController: ToastController, private modalCtrl: ModalController, public formBilder: FormBuilder, ) { }

  ngOnInit() {
    this.productForm = this.formBilder.group({
      name: ['', Validators.required],
      // type: ['', Validators.required],
      price: ['', Validators.required]
      // tax: ['', Validators.required]
    });

    this.list = this.global.proList;
    console.log("list", this.list);

    this.id = this.global.proList.product_id;
    console.log("pro id", this.id);

    this.status = this.global.proList.product_status;
    console.log("status", this.status);
  }

  public removeDots(side: string): string {
    console.log(" Remove Dot ", side);
    return side.replace(/\./g, ",");
  }

  goBack() {
    console.log('Calling go back');
    this.modalCtrl.dismiss();
  }

  async editdetail(formData: any) {
    if (!this.productForm.valid) {
      this.submitAttempt = true;
      // this.presentAlert("Please edit all values");
      console.log(' Some values were not given or were incorrect, please fill them');
    }
    else {
      console.log("click");
      console.log("values", this.id, this.productForm.value);
      let loading = await this.loadingCtrl.create({
        message: 'Please wait...'
      });
      await loading.present();

      let response = this.service.edit_products(this.id, this.productForm.value, this.status);
      console.log("values", this.id, this.productForm.value, this.status);
      response.subscribe(res => {
        loading.dismiss();
        console.log("resonse", res);
        if (res.status == "success") {
          this.toastMsg(res.message);
          // this.navCtrl.navigateForward('/products');
          this.modalCtrl.dismiss();
        }else{
          this.toastMsg(res.message);
        }
        console.log("values", this.id, this.productForm.value);
        
      },
      error => {
        this.toastMsg("Oooops! Please try again later.")
        console.log("Oooops!");
      });

    }
  }

  deldetail() {
    this.presentAlertMultipleButtons("Do you want to delete this product?");
  }

  async presentAlertMultipleButtons(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Are you Sure?',
      message: msg,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Confirm Okay');
            let response = this.service.del_product(this.id);
            response.subscribe(res => {
              console.log("response", res);
              if (res.status == "success") {
                this.toastMsg(res.message);
                this.modalCtrl.dismiss();
                // this.navCtrl.navigateForward('/products');
              }else{
                this.toastMsg(res.message);
              }
            },
            error => {
              this.toastMsg("Oooops! Please try again later.")
              console.log("Oooops!", error);
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async toastMsg(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'middle',
      duration: 2000
    });
    toast.present();
  }
}
