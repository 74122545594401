import { Injectable } from '@angular/core';
import { GlobalVariable } from '../app/global';
import { Http, Response, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ServerService {
  private selectedCust: any;

  constructor(public global: GlobalVariable, public http: Http, public httpC: HttpClient) {

  }

  public setCustomer(customerDetail) {
    this.selectedCust = customerDetail;
  }

  getCustomer() {
    return this.selectedCust;
  }

  login(form: any) {
    let url = this.global.baseUrl + "/users_management.php?user-login";
    let body = new URLSearchParams();
    body.set('email', form.email);
    body.set('password', form.password);
    console.log("Login API Body -> ", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  get_all_products() {
    let url = this.global.baseUrl + "/products_management.php?get-all-products";
    let body = new URLSearchParams();
    // body.set('auth_token', auth_token);
    // body.set('password',loginData.password);
    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  del_product(id) {
    let url = this.global.baseUrl + "/products_management.php?delete-product";
    let body = new FormData();

    body.append('pr_id', id);
    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  edit_products(id, form: any, status) {
    var tax = 0.6*Number(form.pr_price);
    let url = this.global.baseUrl + "/products_management.php?update-product";
    let body = new FormData();
    // body.append('auth_token', auth_token);
    body.append('pr_id', id);
    body.append('pr_name', form.name);
    // body.append('pr_type', form.type);
    body.append('pr_price', form.price);
    body.append('pr_tax', tax.toString());
    body.append('pr_status', status);
    body.append('pr_quantity', '1');

    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  add_product(form: any) {
    var tax = 0.6*Number(form.pr_price);
    let url = this.global.baseUrl + "/products_management.php?add-new-product";
    let body = new URLSearchParams();
    // body.set('auth_token', name);
    body.set('pr_name', form.pr_name);
    // body.set('pr_type', form.pr_type);
    body.set('pr_price', form.pr_price);
    body.set('pr_tax', tax.toString());
    body.set('pr_quantity', '1');

    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  get_all_customers() {
    let url = this.global.baseUrl + "/customers_management.php?get-all-customers";
    let body = new URLSearchParams();

    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  get_customer() {
    let url = this.global.baseUrl + "/customers_management.php?get-all-customers";
    let body = new URLSearchParams();

    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  get_customer_detail(id){
    let url = this.global.baseUrl + "/customers_management.php?get-customer-details";
    let body = new URLSearchParams();

    body.set('cus_id' , id)
    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  add_customers(name, email, address, phone, btwbe) {
    let url = this.global.baseUrl + "/customers_management.php?add-new-customer";
    let body = new URLSearchParams();
    // body.set('auth_token', name);
    body.set('cus_name', name);
    body.set('cus_email', email);
    body.set('cus_address', address);
    body.set('cus_phone', phone);
    body.set('cus_btw', btwbe); 

    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  edit_customer(id, status, name, email, address, phone, btwbe) {
    let url = this.global.baseUrl + "/customers_management.php?update-customer";
    let body = new FormData();
    // body.append('auth_token', auth_token);
    body.append('cus_id', id);
    body.append('cus_status', status);
    body.append('cus_name', name);
    body.append('cus_email', email);
    body.append('cus_address', address);
    body.append('cus_phone', phone);
    body.append('cus_btw', btwbe);

    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  del_customer(id) {
    let url = this.global.baseUrl + "/customers_management.php?delete-customer";
    let body = new FormData();
    // body.set('auth_token', auth_token);
    body.append('cus_id', id);
    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  create_invoice(cus_id,pr_id,pr_qty,pr_subtotal,pr_tax,total_amount,total_tax) {
    let url = this.global.baseUrl + "/invoices_management.php?add-new-invoice";
    let body = new URLSearchParams();

    // var ID =  Math.random().toString(36).substr(2, 9);
    //   console.log(ID);

    body.set('invoice_number', this.global.unique_id);
    body.set('cus_id', cus_id);
    body.set("pr_id", pr_id);
    body.set("pr_qty", pr_qty);
    body.set("pr_subtotal", pr_subtotal);
    body.set("pr_tax" , pr_tax);
    body.set("total_amount", total_amount);
    body.set("total_tax", total_tax);

    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  get_invoice(){
    let url = this.global.baseUrl + "/invoices_management.php?get-all-invoices";
    let body = new FormData();
   
    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  del_invoice(id){
    let url = this.global.baseUrl + "/invoices_management.php?delete-invoice";
    let body = new FormData();
   
    body.append('invoice_id', id);
    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }

  update_invoice(id,cus_id,pr_id,pr_qty,pr_subtotal,form:any){
    let url = this.global.baseUrl + "/invoices_management.php?delete-invoice";
    let body = new FormData();
   
    body.append('invoice_id', id);
    body.append('cus_id', cus_id);
    body.append('pr_id',pr_id);
    body.append('pr_qty',pr_qty);
    body.append('pr_subtotal', pr_subtotal);
    body.append('total_amount',form.total);
    body.append('total_tax',form.total_tax);

    console.log("Body", body);
    return this.http.post(url, body)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)
  }
 
  check_api(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/x-www-form-urlencoded'
      })};
    let url = "http://livesports99.com/application/api/v1/login";
    const formData = new FormData();
    formData.append('email', 'admin@gm.com');
    formData.append('password', 'member321');

    console.log("form data", formData);


    return this.httpC.post(url,formData,httpOptions)
      .do(this.logResponse)
      .map(this.extractData)
      .catch(this.catchError)


    // let body = new FormData();
   
    // body.append('email', 'admin@gm.com');
    // body.append('Passsword', 'member321');
  
    // console.log("Body", body);
    // return this.http.post(url, body)
    //   .do(this.logResponse)
    //   .map(this.extractData)
    //   .catch(this.catchError)
  }



  private catchError(error: Response | any) {
    console.log(error);
    return Observable.throw(error.json().error || "Server error");
  }

  private logResponse(res: Response | any) {
    console.log("server response", res);
    console.log("server response - parsed", res.json());
  }

  private extractData(res: Response) {
    return res.json();
  }

}
