import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ModalController, NavController, LoadingController, AlertController, ToastController } from '@ionic/angular';
import { EmailValidator } from '../validators/email';
import { ServerService } from '../server.service';
import { GlobalVariable } from '../../app/global';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.page.html',
  styleUrls: ['./add-product.page.scss'],
})
export class AddProductPage implements OnInit {
  submitAttempt: boolean = false;
  proForm: FormGroup;
  pr_status: any;

  constructor(public formBilder: FormBuilder, public global: GlobalVariable, private server: ServerService, public loadingCtrl: LoadingController, private modalCtrl: ModalController, public alertController: AlertController, public navCtrl: NavController, public toastController: ToastController) { }

  ngOnInit() {
    this.proForm = this.formBilder.group({
      pr_name: ['', Validators.required],
      // pr_type: ['', Validators.required],
      pr_price: ['', Validators.required],
      // pr_tax: ['', Validators.required]
    });
  }

  goBack(){
    console.log('Calling go back');
    this.modalCtrl.dismiss();
  }

  async add_product(formData: any) {
    if (!this.proForm.valid) {
      this.submitAttempt = true;
      console.log(' Some values were not given or were incorrect, please fill them');
    } else {
      console.log('Values ', this.proForm.value);

      let loading = await this.loadingCtrl.create({
        message: 'Please wait...'
      });
      await loading.present();

      let response = this.server.add_product(this.proForm.value);
      response.subscribe(res => {
        console.log(res);
        loading.dismiss();
        console.log("Products ", res);
        if (res.status == "success") {
          this.toastMsg(res.message);
          this.proForm.reset();
        } else {
          this.presentAlert(res.message);
        }
      },
      error => {
        console.log("Oooops!");
        this.presentAlert(error);
      });
    }
  }

  async presentAlert(msg) {
    const alert = await this.alertController.create({
      header: 'Error',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }

  async toastMsg(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'middle',
      duration: 2000
    });
    toast.present();
  }

}
