import { Component } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoginPage } from '../app/login/login.page';
import { from } from 'rxjs';
import{HomePage} from '../app/home/home.page';
import { Storage } from '@ionic/storage';
import { GlobalVariable } from '../app/global';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  // pages: Array<{title: string, component: any}>;
  rootPage: any = HomePage;
  exampleAppWillEnter: () => void;
  
  constructor(private platform: Platform, private splashScreen: SplashScreen, private statusBar: StatusBar, private menu: MenuController, private nav: NavController,  public storage : Storage,public global:GlobalVariable,) {
    this.initializeApp();
    this.getDataSql();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  openCustom(page) {
    this.menu.close();
    // console.log('rctvbnjk', page);
    this.nav.navigateForward(page);
  }

  getDataSql(){
    console.log("sql code run");
    this.storage.get('company_data').then((data) => {
      console.log('data sql', data);
      this.global.company_name = data.name;
      this.global.title = data.title;
      this.global.address= data.address;
      this.global.bank = data.bank;
      this.global.tel = data.tel;
      console.log('get data 2');
    })
    .catch((error)=>{
      console.log(error); 
    })
  }
}
