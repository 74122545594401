import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'model', loadChildren: './model/model.module#ModelPageModule' },
  { path: 'summary', loadChildren: './summary/summary.module#SummaryPageModule' },
  { path: 'customer', loadChildren: './customer/customer.module#CustomerPageModule' },
  { path: 'add-customer', loadChildren: './add-customer/add-customer.module#AddCustomerPageModule' },
  { path: 'customer-detail', loadChildren: './customer-detail/customer-detail.module#CustomerDetailPageModule' },
  { path: 'products', loadChildren: './products/products.module#ProductsPageModule' },
  { path: 'add-product', loadChildren: './add-product/add-product.module#AddProductPageModule' },
  { path: 'product-detail', loadChildren: './product-detail/product-detail.module#ProductDetailPageModule' },
  { path: 'my-invoice' , loadChildren : './my-invoice/my-invoice.module#MyInvoicePageModule'},
  { path: 'history-summary', loadChildren: './history-summary/history-summary.module#HistorySummaryPageModule' },
  { path: 'setting', loadChildren: './setting/setting.module#SettingPageModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
