import { Component, OnInit } from '@angular/core';

import { ServerService } from '../server.service';
import { GlobalVariable } from '../../app/global';
import { ModalController, NavController, LoadingController, AlertController, ToastController} from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.page.html',
  styleUrls: ['./invoice-detail.page.scss'],
})
export class InvoiceDetailPage implements OnInit {
 // submitAttempt: boolean = false;
  invoiceForm: FormGroup;
  list: any;
  status: any;
  id: any;
  cus_id : any;
  pr_id: any;
pr_qty:any;
pr_subtotal:any;
total_amount:any;
total_tax:any;
  submitAttempt: boolean = false;
  constructor(  public loadingCtrl: LoadingController,public server: ServerService,public global: GlobalVariable, public service: ServerService, private modalCtrl: ModalController, public formBilder: FormBuilder ,  public navCtrl: NavController, public alertCtrl: AlertController, public toastController: ToastController) { }

  ngOnInit() {
    this.invoiceForm = this.formBilder.group({
      // name: ['', Validators.required],
      total : ['', Validators.required],
       total_product : ['', Validators.required],
       total_tax : ['', Validators.required],
    });

    this.list = this.global.invoiceList;
    console.log("list", this.list);

    this.id = this.global.invoiceList.invoice_id;
    console.log(this.id);

    this.cus_id = this.global.invoiceList.customer_id;
    console.log("list", this.cus_id);

    // this.pr_id = this.global.invoiceList.customer_name;
    // console.log("list", this.cus_id);

    // this.pr_qty = this.global.invoiceList.customer_name;
    // console.log("list", this.cus_id);

    // this.pr_subtotal = this.global.invoiceList.customer_name;
    // console.log("list", this.cus_id);

   
  }
  goBack(){
    console.log('Calling go back');
    this.modalCtrl.dismiss();
  }



  async editdetail(formData: any) {

    console.log(this.invoiceForm.value);

    if (!this.invoiceForm.valid) {
      this.submitAttempt = true;
      // this.presentAlert("Please edit all values");
      console.log(' Some values were not given or were incorrect, please fill them');
    }
    else {
      console.log("click");
      console.log("values", this.id, this.invoiceForm.value);
      let loading = await this.loadingCtrl.create({
        message: 'Please wait...'
      });
      await loading.present();

      let response = this.service.update_invoice(this.id, this.cus_id,this.pr_id,this.pr_qty,this.pr_subtotal,this.invoiceForm.value);
      console.log("values", this.id, this.invoiceForm.value, this.status);
      response.subscribe(res => {
        loading.dismiss();
        console.log("resonse", res);
        if (res.status == "success") {
          this.toastMsg(res.message);
          // this.navCtrl.navigateForward('/products');
          this.modalCtrl.dismiss();
        }else{
          this.toastMsg(res.message);
        }
        console.log("values", this.id, this.invoiceForm.value);
        
      },
      error => {
        this.toastMsg("Oooops! Please try again later.")
        console.log("Oooops!");
      });

    }
  }



  deldetail() {
    this.presentAlertMultipleButtons("Do you want to delete this product?");
  }

  async presentAlertMultipleButtons(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Are you Sure?',
      message: msg,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Confirm Okay');
            let response = this.service.del_invoice(this.id);
            response.subscribe(res => {
              console.log("response", res);
              if (res.status == "success") {
                this.toastMsg(res.message);
                this.modalCtrl.dismiss();
                // this.navCtrl.navigateForward('/products');
              }else{
                this.toastMsg(res.message);
              }
            },
            error => {
              this.toastMsg("Oooops! Please try again later.")
              console.log("Oooops!", error);
            });
          }
        }
      ]
    });

    await alert.present();
  }

  async toastMsg(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'middle',
      duration: 2000
    });
    toast.present();
  }
}
