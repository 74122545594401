import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, LoadingController,ToastController } from '@ionic/angular';
import { HomePage } from '../home/home.page';
import { ModalController } from '@ionic/angular';

import { ServerService } from '../server.service';
import { GlobalVariable } from '../../app/global';
import { from } from 'rxjs/observable/from';
import { letProto } from 'rxjs/operator/let';
import { platformBrowser } from '@angular/platform-browser';
@Component({
  selector: 'app-model',
  templateUrl: './model.page.html',
  styleUrls: ['./model.page.scss'],
})
export class ModelPage implements OnInit {
  quantity = 1;
  item_price: any;
  myChoices: any = [];
  price: any;

  products: any;
  place: any;
  loading: any;
  searchProd: any;
  searchItems: any;
  ischecked: boolean = false;
  checked: boolean = true;
  selected_customer: any;

  constructor(public navCtrl: NavController, public navParams: NavParams, public server: ServerService, public global: GlobalVariable, private modalCtrl: ModalController, public loadingCtrl: LoadingController,public toastController: ToastController) {
    console.log("Product in modal ", this.global.prod_modal);
  }

  async ngOnInit() {


  }

  closetohome() {
    this.modalCtrl.dismiss();
  }

  public removeDots(side: string): string {
    return side.replace(/\./g, ",");
  }

  async setFilteredItems() {
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    console.log("Search Product ", this.searchProd);
    loading.dismiss();
    if (this.searchProd != "") {
      loading.dismiss();
      this.searchItems = this.filterItems(this.searchProd);
      console.log("After Search ", this.searchItems);
    }
    else if (this.searchProd == "") {
      this.searchItems = [];
    }

  }

  filterItems(searchTerm) {
    return this.global.prod_modal.filter((item) => {
      console.log("Item ", item);
      return item.product_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });
  }

  emptySearch() {
    this.searchItems = [];
  }

  select() {
    //this.Detail();
    this.global.totalprice();
    this.modalCtrl.dismiss();
    //code by zohra
    //this.server.setCustomer(this.selected_customer);
   // console.log("Selected Customer ", this.selected_customer);
   // console.log("id===", this.selected_customer.customer_id);
    
   // this.toastMsg("please select the customer");
  }

  Detail(event, list, i) {

    // if (this.ischecked == false) {
    //   console.log("false");

    //   if (this.global.Products.push(list)) {
    //     console.log("next if is true")
    //     this.ischecked = true;
    //   }

    // }
    // else if (this.ischecked == true) {
    //   console.log("true")
    //   if (this.global.Products.splice(i, 1)) {
    //     this.ischecked = false;
    //     console.log("if_else")
    //   }
    // }

    //Code by Ayub
    list.product_price = list.product_price.replace(/,/g, '.');
        if (this.global.Products.length != 0) {
     // var temp = false;
      this.global.temp = false;
      for (let i = 0; i < this.global.Products.length; i++) {
        if (Number(list.product_id) == Number(this.global.Products[i].product_id)) {
          this.global.temp = false;
          this.global.Products.splice(i, 1);
          break;
        } else {
          
          this.global.temp = true;
          // this.global.Products.push(list);  
        }
      }
     
      if(this.global.temp){
        this.global.Products.push(list);
      }
    }else {
      this.global.Products.push(list);
    }
    //Code finished

    // this.global.Products.push(list);
    //console.log("list",list)
    console.log("Product Array ", this.global.Products);
    // this.global.totalprice();
    // this.modalCtrl.dismiss();
  }

  priceAdd(p_id) {
    this.global.sum = 0;

    for (let i = 0; i < this.global.Products.length; i++) {
      if (p_id == this.global.Products[i].product_id) {
        console.log("Product ID ", this.global.Products[i].product_id);
        console.log("P ID ", p_id);
        this.global.ProductPrice[p_id] = ((this.global.Products[p_id].product_price * this.global.Products[p_id].product_quantity));
        // console.log(this.ProductPrice[i]);
        this.global.sum = this.global.sum + this.global.ProductPrice[p_id];
      }

    }
  }

  addQuantity(i) {
    console.log("Product ID ", i);
    this.global.Products[i].product_quantity++;
    this.global.Products[i].product_price;
    this.global.totalprice();
  }

}
