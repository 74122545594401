import { Injectable } from '@angular/core'
import { AlertController } from '@ionic/angular';

@Injectable()
export class GlobalVariable {

  baseUrl = "https://sma.mikronexus.com/api/api";
  global_id: any;
  global_username: any;
  global_email: any;
  global_password: any;
  showFabFlag: boolean;
  auth_token = 'docRId22';
  color = '#4e79a6';
  Products: any = [];
  gtax: any;
  total_gtax: any;
  sum: any;
  ProductPrice: any = [];
  ctax: any = [];
  total_ctax: any = [];
  checked: boolean = false;
  ischecked : boolean = false;
  html2: any = [];
  html1: any;
  html3: any;
  html4: any;
  cusList: any = [];
  proList: any = [];
  invoiceList : any =[];
  invoiceSale : any =[];
  selected_item : any = [];
  prod_modal: any = [];
  invoice_id : any;
  cus_check_id : any;
  temp : boolean;
  unique_id : any;
  company_name : any;
  title : any;
  address : any;
  bank : any;
  tel : any;
  
  constructor(public alertCtrl: AlertController) {
    console.log("Hello Global");
    //this.new_method;
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      subHeader: 'Subtitle',
      message: 'This is an alert message.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async EditAlert(header: any, message: any) {
    const alert = await this.alertCtrl.create({
      header: 'Message',
      message: 'Customer Updated Successfully.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async DelAlert(header: any, message: any) {
    const alert = await this.alertCtrl.create({
      header: 'Message',
      message: 'Customer Deleted Successfully.',
      buttons: ['OK']
    });
    await alert.present();
  }

  totalprice() {
    this.sum = 0;

    for (let i = 0; i < this.Products.length; i++) {
      this.ProductPrice[i] = ((this.Products[i].product_price * this.Products[i].product_quantity));
      // console.log(this.ProductPrice[i]);
      this.sum = this.sum + this.ProductPrice[i];
    }
  }

 
  addValue() {
    console.log("Fun check value ", this.checked);
    var temp;

    if (this.checked == false) {
      this.gtax = (this.sum * 6) / 100;
      this.total_gtax = this.gtax + this.sum;
      console.log("Tax ",this.gtax);
      console.log("++", this.total_gtax);

      //this.print_value();
      console.log("length:", this.Products.length)
      
      for (let i = 0; i < this.Products.length; i++) {
        console.log("length:", this.Products.length)

        this.ctax[i] = (this.ProductPrice[i] * 6) / 100;
        this.total_ctax[i] = this.ctax[i] + this.ProductPrice[i];
        console.log("cost tax", this.ctax);
        console.log("total cost tax", this.total_ctax);
      }
    }
    else {
      this.total_gtax = '';
      console.log("elseeeeee")

      for (let i = 0; i < this.Products.length; i++) {
        this.total_ctax[i] = '';
      }
    }
  }

  forHome() {
    this.gtax = (this.sum * 6) / 100;
    this.total_gtax = this.gtax + this.sum;
    console.log(this.gtax);
    console.log("++", this.total_gtax);

    //this.print_value();
    console.log("length:", this.Products.length)
    for (let i = 0; i < this.Products.length; i++) {
      console.log("length:", this.Products.length)

      this.ctax[i] = (this.ProductPrice[i] * 6) / 100;
      this.total_ctax[i] = this.ctax[i] + this.ProductPrice[i];
      console.log("cost tax", this.ctax);
      console.log("total cost tax", this.total_ctax);
    }
  }

 
  
  

}