import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { ModalController, NavController, LoadingController, AlertController, ToastController } from '@ionic/angular';
import { EmailValidator } from '../validators/email';
import { ServerService } from '../server.service';
import { GlobalVariable } from '../../app/global';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.page.html',
  styleUrls: ['./add-customer.page.scss'],
})
export class AddCustomerPage implements OnInit {
  submitAttempt: boolean = false;
  cusForm: FormGroup;

  constructor(public formBilder: FormBuilder, public global: GlobalVariable, private server: ServerService, public loadingCtrl: LoadingController, private modalCtrl: ModalController, public alertController: AlertController, public navCtrl: NavController, public toastController: ToastController) { }

  ngOnInit() {
    this.cusForm = this.formBilder.group({
      email: ['', Validators.compose([Validators.required, EmailValidator.isValid])],
      phone: ['', Validators.compose([Validators.minLength(10), Validators.required])],
      name: ['', Validators.required],
      address: ['', Validators.required],
      btwBe: ['', Validators.required]
    });
  }

  goBack(){
    console.log('Calling go back');
    this.modalCtrl.dismiss();
  }

  async createCus(formData: any) {
    if (!this.cusForm.valid) {
      this.submitAttempt = true;
      console.log(' Some values were not given or were incorrect, please fill them');
    } else {
      console.log('Values ', this.cusForm.value.name);
      let loading = await this.loadingCtrl.create({
        message: 'Please wait...'
      });
      await loading.present();

      let res_customer = this.server.add_customers(this.cusForm.value.name, this.cusForm.value.email, this.cusForm.value.address, this.cusForm.value.phone, this.cusForm.value.btwBe);
      res_customer.subscribe(res => {
        loading.dismiss();
        console.log("Customers ", res);
        if (res.status == "success") {
          this.toastMsg(res.message);
          this.cusForm.reset();
        }else{
          this.presentAlert(res.message);
        }
      },
      error => {
        console.log("Oooops!");
        this.presentAlert(error);
      });
    }
  }

  async presentAlert(msg) {
    const alert = await this.alertController.create({
      header: 'Error',
      message: msg,
      buttons: ['OK']
    });
    await alert.present();
  }

  async toastMsg(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'middle',
      duration: 2000
    });
    toast.present();
  }

}
