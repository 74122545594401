//import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler } from '@angular/core';
//import { IonicApp, IonicErrorHandler } from '@ionic/angular';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { from } from 'rxjs';

import { ModelPage } from '../app/model/model.page';
import { AddCustomerPage } from '../app/add-customer/add-customer.page';
import { CustomerDetailPage } from '../app/customer-detail/customer-detail.page';
import { AddProductPage } from '../app/add-product/add-product.page';
import { ProductDetailPage } from '../app/product-detail/product-detail.page';
import { InvoiceDetailPage } from '../app/invoice-detail/invoice-detail.page';

import { ServerService } from '../app/server.service';
import { GlobalVariable } from '../app/global';
import { OrderModule } from 'ngx-order-pipe';

import { IonicStorageModule } from '@ionic/storage';

import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';

@NgModule({
  declarations: [AppComponent,
    ModelPage, AddCustomerPage,
    CustomerDetailPage,
    AddProductPage,
    ProductDetailPage,
    InvoiceDetailPage],
  entryComponents: [
    ModelPage,
    AddCustomerPage,
    CustomerDetailPage,
    AddProductPage,
    ProductDetailPage,
    InvoiceDetailPage,
   
  ],
  imports: [
    FormsModule,
    OrderModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
   
  ],
  providers: [
    ServerService,
    GlobalVariable,
    StatusBar,
    SplashScreen,
    PDFGenerator,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
