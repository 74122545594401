import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalVariable } from '../global';
import { from } from 'rxjs';
import { ServerService } from '../server.service';
import { ModalController, NavController, LoadingController, AlertController, ToastController } from '@ionic/angular';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.page.html',
  styleUrls: ['./customer-detail.page.scss'],
})
export class CustomerDetailPage implements OnInit {
  name: any;
  address: any;
  email: any;
  phone: any;
  status: any;
  id: any;
  btwBe: any;

  constructor(public route: ActivatedRoute, public global: GlobalVariable, public service: ServerService, public navCtrl: NavController, public alertCtrl: AlertController, public router: Router, public loadingCtrl: LoadingController, public toastController: ToastController, private modalCtrl: ModalController) {

  }

  ngOnInit() {
    console.log("hello Customer detail Page");
    this.id = this.global.cusList.customer_id;
    console.log(this.id);
    this.status = "1";
    console.log(this.status);
    this.name = this.global.cusList.customer_name;
    console.log("list", this.name);
    this.email = this.global.cusList.customer_email;
    console.log("list", this.email);
    this.address = this.global.cusList.customer_address;
    console.log("list", this.address);
    this.phone = this.global.cusList.customer_phone;
    console.log("Phone list", this.phone);
    this.btwBe = this.global.cusList.customer_btw;
    console.log("list", this.btwBe);
  }

  goBack(){
    console.log('Calling go back');
    this.modalCtrl.dismiss();
  }

  async editdetail() {
    let loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    let response = this.service.edit_customer(this.id, this.status, this.name, this.email, this.address, this.phone, this.btwBe);
    response.subscribe(res => {
      console.log(res);
      loading.dismiss();
      if (res.status == "success") {
        this.toastMsg('Customer updated Successfully');
        // this.navCtrl.navigateBack('/customer');
        this.modalCtrl.dismiss();
      }else{
        this.toastMsg('Something went wrong');
      }
      // this.global.EditAlert(Headers, Message);
      // this.navCtrl.pop();
      // this.navCtrl.navigateBack('/customer');
      // this.router.navigateByUrl('/customer');
    },
    error => {
      console.log("Oooops!");
      this.toastMsg(error);
    });
  }

  deldetail() {
    this.presentAlertMultipleButtons("Do you want to delete this customer?");
  }

  async presentAlertMultipleButtons(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Are you Sure?',
      message: msg,
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Confirm Okay');
            let response = this.service.del_customer(this.id);
            response.subscribe(res => {
              console.log("response", res);
              if (res.status == "success") {
                this.toastMsg(res.message);
                this.modalCtrl.dismiss();
                // this.navCtrl.navigateForward('/products');
              }else{
                this.toastMsg(res.message);
              }
            },
            error => {
              this.toastMsg("Oooops! Please try again later.")
              console.log("Oooops!", error);
            });
          }
        }
      ]
    });

    await alert.present();
  }







  // async deldetail() {
  //   let loading = await this.loadingCtrl.create({
  //     message: 'Please wait...'
  //   });
  //   await loading.present();

  //   let response = this.service.del_customer(this.id);
  //   response.subscribe(res => {
  //     console.log(res);
  //     loading.dismiss();
  //     if (res.status == "success") {
  //       this.toastMsg('Customer deleted Successfully');
  //       this.modalCtrl.dismiss();
      
  //     }else{
  //       this.toastMsg('Something went wrong');
  //     }
     
  //   },
  //   error => {
  //     console.log("Oooops!");
  //     this.toastMsg(error);
  //   });
  // }

  async toastMsg(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'middle',
      duration: 2000
    });
    toast.present();
  }

}
