import { Component } from '@angular/core';
import { Printer, PrintOptions } from '@ionic-native/printer';
import { ModalController, NavController, LoadingController, ToastController } from '@ionic/angular';
import { GlobalVariable } from '../../app/global';
import { ServerService } from '../server.service';
import { ModelPage } from '../model/model.page';
//import {SummaryPage } from '../summary/summary.page';
import { from } from 'rxjs';
//import { MenuController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})

export class HomePage {

  newPro: any;
  ProductId: any;
  ProductName: any;

  quantity = 1;
  myChoices: any = [];
  price: any;
  ProductPrice: any = [];

  sum = 0;
  name: any;
  customers: any;
  selected_customer: any;
  task: any;
  ID: any;
  searchCustomer: any;
  searchItems: any;
  search : boolean = false;
  status : boolean = true;

  constructor(private modalCtrl: ModalController, public navCtrl: NavController, public global: GlobalVariable, public server: ServerService, public loadingCtrl: LoadingController,public toastController: ToastController) {

   
    // this.task = setInterval(() => {
    //   let res_customer = this.server.get_all_customers();
    //   res_customer.subscribe(res => {
    //     this.customers = res.data;
    //     console.log(" Interval Customers ", this.customers);
    //   });
    // }, 10000);
   
   
      
     
  }

  
  public removeDots(side: string): string {
    return side.replace(/\./g, ",");
  }

  public removeDotsToStr(side: string): string {
    let str = String(side);
    return str.replace(/\./g, ",");
  }

  exampleAppWillEnter() {
    // Gets called whenever navigated
    let res_customer = this.server.get_all_customers();
    res_customer.subscribe(res => {
      this.customers = res.data;
      // this.global.cus_check_id = this.customers.Customer_id;
      // console.log("customer global id",this.global.cus_check_id);
      // console.log(" Interval Customers ", this.customers);
    });
   

    let response = this.server.get_all_products();
    response.subscribe(res => {
      //console.log(res);
      this.global.prod_modal = res.data;
     // console.log(" Interval Products ",this.global.prod_modal);

    });
//this.global.temp= false;
  }

  async ngOnInit() {
    // let loading = await this.loadingCtrl.create({
    //   message: 'Please wait...'
    // });
    // await loading.present();

    let res_customer = this.server.get_all_customers();
    res_customer.subscribe(res => {
    //  console.log(res);
      // loading.dismiss();
      this.customers = res.data;
     
    //  console.log("Customers ", this.customers);
    });
    
  }

  async presentModal() {
    const modal = await this.modalCtrl.create({
      component: ModelPage,
      componentProps: { value: 123 }
    });
    return await modal.present();
  }

  // cus_select(event,list){
  //   console.log(event)
  //   console.log("hello");
  //   this.server.setCustomer(this.selected_customer);
  //   console.log(this.selected_customer);
  // }

  go_Summary() {
    console.log("Selected Customer ", this.selected_customer);
    this.global.totalprice();
    console.log("on Home", this.global.checked);
    if (this.global.checked == true) {
      console.log("inside Home", this.global.checked);
      this.global.forHome();
    }

    this.server.setCustomer(this.selected_customer)
    this.navCtrl.navigateForward('/summary');
  }

  addQuantity(i) {
    this.global.Products[i].product_quantity++;
    this.global.Products[i].product_price;
    this.global.totalprice();
  }

  del_product(i, id) {
    this.global.Products.splice(i, 1);
    this.global.totalprice();
   
    for (let j = 0; j < this.global.prod_modal.length; j++) {
      if (id == this.global.prod_modal[j].product_id) {
        console.log("ID equal", this.global.prod_modal[j]);
        this.global.prod_modal[j].ischecked = false;
      }
    }
    
    this.global.totalprice();
    console.log("Product Array ", this.global.Products); 
  }

  clear(i) {
    this.global.sum = 0;
    this.global.Products.splice(i);

    for (let j = 0; j < this.global.prod_modal.length; j++) {
     
        console.log("ID equal", this.global.prod_modal[j]);
        this.global.prod_modal[j].ischecked = false;
      
    }
    console.log(this.global.Products.length);
   // this.exampleAppWillEnter();
    console.log("Product Array ", this.global.Products);
  }

  remove_Quantity(i) {
    if (this.global.Products[i].product_quantity <= 1) {
      this.global.Products[i].product_quantity = 1;
    }
    else {
      this.global.Products[i].product_quantity -= 1;
    }

    this.global.totalprice();
  }

  async setFilteredCustomer() {

    this.search = true;

    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });
    await loading.present();

    console.log("Search Customer", this.searchCustomer);
    loading.dismiss();
    if (this.searchCustomer != "") {
      loading.dismiss();
      this.searchItems = this.filterItems(this.searchCustomer);
      console.log("After Search ", this.searchItems);
    }
    else if (this.searchCustomer == "") {
      this.status = false;
      this.searchItems = [];
    }

  }

  filterItems(searchTerm) {
    // return  this.customers.filter((item) => {
    //   console.log("Item ", item);
    //   return item.customer_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    // });
  }

  emptySearch() {
    this.search = false;
    this.status = false;
    this.searchItems = [];
  }



  async presentLoading() {
    const loadingController = document.querySelector('ion-loading-controller');
    await loadingController.componentOnReady();

    const loadingElement = await loadingController.create({
      message: 'Please wait...',
      spinner: 'crescent',
      duration: 2000
    });
    return await loadingElement.present();
  }

  async toastMsg(msg) {
    const toast = await this.toastController.create({
      message: msg,
      position: 'middle',
      duration: 2000
    });
    toast.present();
  }

 

}
